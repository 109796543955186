import {
    EmailInvalidError,
    InvalidFirstNameError,
    InvalidLastNameError,
    MattermostUsernameReservedError,
    MattermostUsernameInvalidError,
    PasswordInvalidError,
    PasswordsNotEqualError
} from "./Errors";


export function validFirstName(firstName: string) {
    if (isEmptyTrimmedName(firstName))
        throw new InvalidFirstNameError();
}

export function validLastName(lastName: string) {
    if (isEmptyTrimmedName(lastName))
        throw new InvalidLastNameError();
}

export function validMattermostUsername(name: string) {
    if (name === "")
        return true;
    let validUsernameChars = new RegExp(`^[a-z0-9.\\-_]+$`);
    const MIN_LENGTH = 3;
    const MAX_LENGTH = 22;
    if (!isInRange(name.length, MIN_LENGTH, MAX_LENGTH) || !validUsernameChars.test(name))
        throw new MattermostUsernameInvalidError()
    if (isReservedMattermostUserName(name))
        throw new MattermostUsernameReservedError()
}

export function validatePasswords(password: string, otherPassword: string) {
    if (!arePasswordsEqual(password, otherPassword))
        throw new PasswordsNotEqualError();
    if (!isValidPassword(password)) {
        throw new PasswordInvalidError();
    }
}

export function validateEmail(email: string) {
    if (email === "")
        throw new EmailInvalidError();
    const regex = RegExp("^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*" +
        "@[a-zA-Z](-?[a-zA-Z0-9])*(\\.[a-zA-Z](-?[a-zA-Z0-9])*)+$");
    if (!regex.test(email))
        throw new EmailInvalidError()
}

function isValidPassword(password: string): boolean {
    if (password.length < 8)
        return false;
    const lowercaseLetters = RegExp("[a-z]");
    const uppercaseLetters = RegExp("[A-Z]");
    const numbers = RegExp("[0-9]");
    const specialCharacters = RegExp("[" + escapeRegExp("^$*.[]{}()?-\"!@#%&/\\,><':;|_~`+=") + "]");
    return lowercaseLetters.test(password)
        && uppercaseLetters.test(password)
        && numbers.test(password)
        && specialCharacters.test(password);

    function escapeRegExp (text: string): string {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

}

function arePasswordsEqual(password: string, otherPassword: string) {
    return password === otherPassword;
}

function isEmptyTrimmedName(name: string) {
    return name.trim() === "";
}

function isReservedMattermostUserName(mattermostUsername: string) {
    const reservedNames: string[] = process.env.REACT_APP_RESERVED_MATTERMOST_USERNAMES!.split(",");
    for (let name of reservedNames)
        if (mattermostUsername.trim() === name.trim())
            return true;
    return false;
}

function isInRange(number: number, closedLowerBound: number, closedUpperBound: number) {
    return number >= closedLowerBound && number <= closedUpperBound;
}