import {CustomErrors} from "./ErrorHandler";

export class PasswordsNotEqualError extends Error {
    constructor() {
        super("Die Passwörter müssen übereinstimmen!");
        this.name = CustomErrors.PASSWORDS_NOT_EQUAL
    }
}


export class PasswordInvalidError extends Error {
    constructor() {
        super("Das Passwort muss 8 Zeichen oder mehr umfassen, mindestens einen Groß- und " +
            "Kleinbuchstaben, eine Zahl sowie eines der folgenden Sonderzeichen enthalten: ^$*.[]{}()?-\"!@#%&/\\,><':;|_~`+=!");
        this.name = CustomErrors.PASSWORD_INVALID
    }
}

export class InvalidFirstNameError extends Error {
    constructor() {
        super("Bitte einen Vornamen angeben!");
        this.name = CustomErrors.INVALID_FIRST_NAME
    }
}

export class InvalidLastNameError extends Error {
    constructor() {
        super("Bitte einen Nachnamen angeben!");
        this.name = CustomErrors.INVALID_LAST_NAME
    }
}

export class MattermostUsernameInvalidError extends Error {
    constructor() {
        super("Der Mattermostname darf nur Kleinbuchstaben enthalten, muss 3 - 22 Zeichen lang sein, " +
            "mit einem Buchstaben beginnen und darf außerdem nur Zahlen, und die Symbole '.', '-' und " +
            "'_' enthalten!.");
        this.name = CustomErrors.MATTERMOST_USERNAME_INVALID
    }
}

export class MattermostUsernameReservedError extends Error {
    constructor() {
        super("Dieser Mattermostname ist vom System reserviert. Bitte wähle einen anderen!");
        this.name = CustomErrors.MATTERMOST_USERNAME_RESERVED
    }
}

export class EmailInvalidError extends Error {
    constructor() {
        super("Bitte eine gültige E-Mail-Adresse angeben!");
        this.name = CustomErrors.EMAIL_INVALID
    }
}


export class QueryParamsInvalidError extends Error {
    constructor() {
        super("Der Link ist ungültig. Erstelle bitte einen neuen!");
        this.name = CustomErrors.QUERY_PARAMS_INVALID
    }
}
